import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, createSelector } from '@ngrx/store';
import { map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { AppState } from 'src/app/app.state';
import { sendPaymentComplete } from 'src/app/core/analytics/analytics.actions';
import { ApiStatus } from 'src/app/core/api/api.interface';
import { ApiService } from 'src/app/core/api/api.service';
import { PaymentAuthResponse } from 'src/app/core/api/endpoints/payment-auth';
import { sendMessageAction } from 'src/app/core/application-bridge/application-bridge.actions';
import {
  AuthorizedPaymentInfo,
  PaymentComplete,
  PaymentInfo,
  PaymentType,
  SplitPaymentComplete,
} from 'src/app/core/application-bridge/application-bridge.models';
import { WINDOW } from 'src/app/core/injection-token/window/window';
import { LocaleService } from 'src/app/core/locale/locale.service';
import { NotificationDialogType } from 'src/app/core/notification/dialog/confirm-dialog/confirm-dialog.component';
import { showNotificationAction } from 'src/app/core/notification/notification.actions';
import { getErrorLocaleFromResponse } from 'src/app/core/notification/notification.utils';
import { showPageSpinner } from 'src/app/core/page-spinner/page-spinner.actions';
import { User } from 'src/app/core/parent-config/parent-config.state';
import { GiftCardPayment } from 'src/app/core/payment-configuration/payment-configuration.model';
import { selectGiftCard } from 'src/app/core/payment-configuration/payment-configuration.selectors';
import { routeToWithOptions } from 'src/app/core/routing/routing.actions';
import { setAuthorizingAction } from 'src/app/core/session/session.actions';
import { TemporaryLoggerService } from 'src/app/core/temporary-logger/temporary-logger.service';
import { hasPreAuthGiftCards } from 'src/app/feature/gift-card/gift-card.utils';
import { EVENT_BILLING_DEMOGRAPHICS, EVENT_PAYMENT_FAILED } from 'src/app/shared/enums/application-bridge.enums';
import { selectTotalAmount } from 'src/app/shared/selectors/configuration.selectors';
import { returnOrDefaultString } from 'src/app/shared/utilities/string.utils';
import { validArray } from 'src/app/shared/utilities/types.utils';
import { getQueryParamsFromUrl } from 'src/app/shared/utilities/url.utils';
import { Cardinal3DSService } from '../cybersource/credit-card/cardinal-3ds.service';
import { selectGiftCardState, selectIsSplitPayment } from '../gift-card/gift-card.selectors';
import { selectPaymentMethods } from '../payment/payment.selectors';
import {
  gatherPaymentDetailsAdyen3DSAction,
  gatherPaymentDetailsForAdyenWebComponentAction,
  gatherPaymentDetailsForAmazonPayAction,
  gatherPaymentDetailsForApplePayAction,
  gatherPaymentDetailsForCreditCardAction,
  gatherPaymentDetailsForCybersource3DSAction,
  gatherPaymentDetailsForGiftCardOnlyPaymentAction,
  gatherPaymentDetailsForGooglePayAction,
  gatherPaymentDetailsForPayPalAction,
  gatherPaymentDetailsForRedirectPaymentAction,
  gatherPaymentDetailsForStoredWalletAction,
  gatherPaymentDetailsForUpliftPaymentAction,
  giftCardSplitPaymentWithCybersource3DSSuccessAction,
  initializeGiftCardSplitPaymentWithCybersource3DSAction,
  initiateCybersource3DSTransactionAction,
  routeToRedirectResultAction,
  sendPaymentAuthorized,
  sendPaymentFailed,
  sendPaymentPending,
  updateAlternatePaymentDemographicsAction,
  updateBillingDemographicsAction,
} from './billing.actions';
import { selectPaymentCompleteUserDetails, selectPreAuthGiftCardPaymentDetails } from './billing.selectors';
import { formatMultiAuthPayments, formatSingleAuthPayment } from './billing.utils';
import { initiateCybersourceGooglePay3DSTransactionAction } from './google-pay/google-pay.actions';

const paymentInfoSelector = createSelector(
  selectTotalAmount,
  selectPreAuthGiftCardPaymentDetails,
  selectPaymentCompleteUserDetails,
  selectPaymentMethods,
  selectGiftCard,
  (totalAmount, preAuthGiftCards, user, payments, giftCardConfiguration) => {
    return {
      totalAmount,
      preAuthGiftCards,
      user,
      payments,
      giftCardConfiguration,
    };
  }
);

@Injectable({
  providedIn: 'root',
})
export class BillingEffects {
  /**
   * The constructor
   * @param actions$ Our action observable
   * @param store The store
   * @param adyen3DSService Adyen 3ds service
   * @param localeService The locale service
   * @param window Window
   */
  constructor(
    @Inject(WINDOW) private window: Window,
    private actions$: Actions,
    private store: Store<AppState>,
    private cardinalService: Cardinal3DSService,
    private tempLogger: TemporaryLoggerService,
    private apiService: ApiService,
    private localeService: LocaleService
  ) {}

  /**
   * This effect is triggered when the user clicks on Pay
   * and the card is enrolled for 3DS
   */
  initializeCybersource3ds$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(initiateCybersource3DSTransactionAction, initiateCybersourceGooglePay3DSTransactionAction),
        withLatestFrom(
          this.store.select(createSelector(selectGiftCardState, selectTotalAmount, (giftCardState, totalAmount) => ({ giftCardState, totalAmount })))
        ),
        tap(() => this.store.dispatch(setAuthorizingAction({ authorizing: true }))),
        map(([action, currStates]) => {
          if (currStates.giftCardState.totalBalance > 0 && !hasPreAuthGiftCards(currStates.giftCardState.giftcards)) {
            this.store.dispatch(initializeGiftCardSplitPaymentWithCybersource3DSAction({ amount: currStates.totalAmount, payload: action.payload }));
          } else {
            this.cardinalService.initiateCardinal3DS(action.payload);
          }
        })
      ),
    { dispatch: false }
  );

  /**
   * Completes split payment
   */
  completeSplitPayment$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(giftCardSplitPaymentWithCybersource3DSSuccessAction),
        map((action) => this.cardinalService.initiateCardinal3DS(action.payload))
      ),
    { dispatch: false }
  );

  /**
   * This method is called whenever you update the billing demographics so that the parent application
   * knows about the information.
   */
  updateDemographics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateAlternatePaymentDemographicsAction, updateBillingDemographicsAction),
      map(({ payload }) => {
        return sendMessageAction({
          key: EVENT_BILLING_DEMOGRAPHICS,
          payload: {
            firstName: returnOrDefaultString(payload?.firstName),
            lastName: returnOrDefaultString(payload?.lastName),
            middleInitial: returnOrDefaultString(payload?.middleInitial),
            email: returnOrDefaultString(payload?.email),
            phone: returnOrDefaultString(payload?.phone),
            phoneCountryCode: returnOrDefaultString(payload?.telCode),
            phoneRegionCode: returnOrDefaultString(payload?.regionCode),
            address1: returnOrDefaultString(payload?.address1),
            address2: returnOrDefaultString(payload?.address2),
            city: returnOrDefaultString(payload?.city),
            stateProvince: returnOrDefaultString(payload?.state),
            country: returnOrDefaultString(payload?.country),
            zipPostal: returnOrDefaultString(payload?.zip),
          } as User,
        });
      })
    )
  );

  /**
   * This effect is triggered when the user is redirected back after completing payment
   */
  routeToRedirectResults$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routeToRedirectResultAction),
      map(() =>
        routeToWithOptions({
          routeParams: ['redirect-result'],
          options: { queryParams: getQueryParamsFromUrl(this.window) },
        })
      )
    )
  );

  /**
   * This is triggered when the payment has be authorized and we are ready to check
   * for partial payments or finalize payment details
   */
  gatherPaymentDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        gatherPaymentDetailsForStoredWalletAction,
        gatherPaymentDetailsForCreditCardAction,
        gatherPaymentDetailsForCybersource3DSAction,
        gatherPaymentDetailsAdyen3DSAction,
        gatherPaymentDetailsForAmazonPayAction,
        gatherPaymentDetailsForGooglePayAction,
        gatherPaymentDetailsForApplePayAction,
        gatherPaymentDetailsForPayPalAction,
        gatherPaymentDetailsForRedirectPaymentAction,
        gatherPaymentDetailsForUpliftPaymentAction,
        gatherPaymentDetailsForAdyenWebComponentAction
      ),
      withLatestFrom(this.store.select(paymentInfoSelector)),
      mergeMap(([{ payload }, { totalAmount, preAuthGiftCards, user, payments, giftCardConfiguration }]) => {
        if (payments.length > 0) {
          return this.apiService.paymentAuth().pipe(
            mergeMap((apiResponse) => {
              if (apiResponse.status === ApiStatus.FAILED) {
                return [
                  setAuthorizingAction({ authorizing: false }),
                  showNotificationAction({
                    buttonLabel: 'close',
                    dialogType: NotificationDialogType.GENERAL,
                    initiator: 'payment auth failure',
                    message: getErrorLocaleFromResponse(this.localeService, apiResponse),
                  }),
                  sendPaymentFailed({ payload: { paymentType: PaymentType.GIFT_CARD } }),
                ];
              }

              return this.processPaymentFlow({
                payload,
                preAuthGiftCards,
                giftCardConfiguration,
                user,
                additionalPayments: apiResponse.response,
              });
            })
          );
        }

        return this.processPaymentFlow({
          payload,
          preAuthGiftCards,
          user,
          giftCardConfiguration,
        });
      })
    )
  );

  private processPaymentFlow({
    payload,
    preAuthGiftCards,
    user,
    additionalPayments,
    giftCardConfiguration,
  }: {
    payload: AuthorizedPaymentInfo;
    preAuthGiftCards: PaymentInfo[];
    user: PaymentComplete['user'];
    additionalPayments?: PaymentAuthResponse;
    giftCardConfiguration: GiftCardPayment;
  }) {
    let paymentCompletePayload: SplitPaymentComplete | PaymentComplete;

    if (preAuthGiftCards.length) {
      const { billing } = user;
      const paymentInfo: PaymentInfo[] = [{ ...payload, billing }, ...preAuthGiftCards];

      if (additionalPayments) {
        additionalPayments.payments.forEach((payment) => {
          paymentInfo.push({
            amount: payment.amount,
            method: PaymentType.TESCO_VOUCHER, // !! Need to get this from the payment response
            authId: payment.authId,
            legacy: {
              authId: payment.authId,
              paymentMerchantId: giftCardConfiguration.paymentMerchantId,
              paymentMethodCode: payment.paymentMethodCode,
              paymentProviderName: payment.provider,
            },
            billing: {},
          });
        });
      }

      paymentCompletePayload = formatMultiAuthPayments(paymentInfo, user);
    } else {
      paymentCompletePayload = formatSingleAuthPayment(payload, user);
    }

    this.tempLogger.log('billing-effects', 'gather payment details complete - send payment');
    return [showPageSpinner({ initiator: 'Send Payment Details to Analytics' }), sendPaymentComplete({ payload: paymentCompletePayload })];
  }

  /**
   * This is triggered when the payment has been made only though giftcard/s
   */
  gatherGiftCardOnlyPaymentDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(gatherPaymentDetailsForGiftCardOnlyPaymentAction),
      withLatestFrom(this.store.select(selectPaymentCompleteUserDetails)),
      mergeMap(([{ payload: svcAuthPayments }, user]) => {
        let paymentCompletePayload: SplitPaymentComplete | PaymentComplete;

        if (validArray(svcAuthPayments) && svcAuthPayments.length === 1) {
          const [{ billing, ...paymentInfo }] = svcAuthPayments as PaymentInfo[];
          paymentCompletePayload = formatSingleAuthPayment(paymentInfo, user);
        } else {
          paymentCompletePayload = formatMultiAuthPayments(svcAuthPayments as PaymentInfo[], user);
        }

        this.tempLogger.log('billing-effects', 'finalize complete - send payment');

        return [showPageSpinner({ initiator: 'Send Payment Details to Analytics' }), sendPaymentComplete({ payload: paymentCompletePayload })];
      })
    )
  );

  /**
   * Format and send payment complete event for authorized payments
   */
  handleAuthorizedPayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendPaymentAuthorized),
      withLatestFrom(
        this.store.select(
          createSelector(
            selectIsSplitPayment,
            selectPreAuthGiftCardPaymentDetails,
            selectPaymentCompleteUserDetails,
            (isSplitPayment, preAuthGiftCards, user) => ({ isSplitPayment, preAuthGiftCards, user })
          )
        )
      ),
      mergeMap(([{ payload: paymentAuth }, { isSplitPayment, preAuthGiftCards, user }]) => {
        let paymentCompletePayload = <PaymentComplete | SplitPaymentComplete>{};

        const primaryPayment = <PaymentInfo>{ ...paymentAuth, awaitingAuthorization: false };

        if (isSplitPayment && preAuthGiftCards.length) {
          primaryPayment.billing = user.billing;
          paymentCompletePayload = formatMultiAuthPayments([primaryPayment, ...preAuthGiftCards], user);
        } else {
          paymentCompletePayload = formatSingleAuthPayment(primaryPayment, user);
        }

        return [showPageSpinner({ initiator: 'Send Payment Details to Analytics' }), sendPaymentComplete({ payload: paymentCompletePayload })];
      })
    )
  );

  /**
   * Format and send payment complete event for pending payments
   */
  handlePendingPayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendPaymentPending),
      withLatestFrom(
        this.store.select(
          createSelector(
            selectIsSplitPayment,
            selectPreAuthGiftCardPaymentDetails,
            selectPaymentCompleteUserDetails,
            (isSplitPayment, preAuthGiftCards, user) => ({ isSplitPayment, preAuthGiftCards, user })
          )
        )
      ),
      mergeMap(([{ payload: pendingPayment }, { isSplitPayment, preAuthGiftCards, user }]) => {
        let paymentCompletePayload = <PaymentComplete | SplitPaymentComplete>{};

        const primaryPayment = <PaymentInfo>{
          ...pendingPayment,
          awaitingAuthorization: true,
        };

        if (isSplitPayment && preAuthGiftCards.length) {
          primaryPayment.billing = user.billing;
          paymentCompletePayload = formatMultiAuthPayments([primaryPayment, ...preAuthGiftCards], user);
        } else {
          paymentCompletePayload = formatSingleAuthPayment(primaryPayment, user);
        }

        return [showPageSpinner({ initiator: 'Send Payment Details to Analytics' }), sendPaymentComplete({ payload: paymentCompletePayload })];
      })
    )
  );

  /**
   * Handles failed payment event
   */
  handleFailedPayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendPaymentFailed),
      map(({ payload }) => sendMessageAction({ key: EVENT_PAYMENT_FAILED, payload }))
    )
  );
}
