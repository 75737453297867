import { Action } from '@ngrx/store';
import { sendAnalyticsEventAction } from 'src/app/core/analytics/analytics.actions';
import { AuthorizeResponse } from 'src/app/core/api/responses/authorize';
import { PaymentMethod } from 'src/app/core/api/responses/get-payment-configuration';
import { AuthorizedPaymentInfo, PaymentFailed, PaymentType } from 'src/app/core/application-bridge/application-bridge.models';
import {
  AlternatePayment,
  CreditCard,
  PaymentMethodCode,
  PaymentProviderType,
  WalletPayment,
} from 'src/app/core/payment-configuration/payment-configuration.model';
import { sendPaymentFailed } from 'src/app/feature/billing/billing.actions';
import { normalize } from 'src/app/shared/utilities/string.utils';
import { validArray, validObject, validString } from 'src/app/shared/utilities/types.utils';
import { CreditCardBrand } from '../enums/billing.enums';
import { ERROR_CODE_RESPONSE_NOT_OBJECT } from '../enums/error-code.enums';
import { isCybersource, isPaymentProvider } from './alternate-payment.utils';
import { convertDonationResponse } from './donation.utils';
import { convertInsuranceResponse } from './insurance.utils';

/**
 * Finds a payment type thats not a credit card.
 * @param type The payment type to test
 */
export function filterForCreditCard(type: PaymentMethod): boolean {
  return type.paymentMethodCode === PaymentMethodCode.CREDIT_CARD;
}

/**
 * Filter valid credit card types
 * @param cardBrandName Card Brand names
 * @param card Credit Card
 */
export function filterValidCardBrands(cardBrandNames: string[] = [], card: PaymentMethod): boolean {
  if (!validArray(cardBrandNames)) {
    return true;
  }

  const normalizedCardBrandNames = cardBrandNames.map(normalize);
  const normalizedCardName = normalize(card.cardBrandName);

  return !normalizedCardBrandNames.includes(normalizedCardName);
}

/**
 * Find out if a payment type is not a credit card payment type
 * @param type The payment type to test
 */
export function filterAlternatePaymentMethods(type: PaymentMethod): boolean {
  const nonAlternatePaymentMethodCodes = [PaymentMethodCode.CREDIT_CARD, PaymentMethodCode.APPLE_PAY, PaymentMethodCode.GOOGLE_PAY, PaymentMethodCode.SVC];
  return !nonAlternatePaymentMethodCodes.includes(type.paymentMethodCode);
}

/**
 * Find out if a payment type is an apple pay credit card
 * @param type The payment type to test
 */
export function filterForWalletPayments(type: PaymentMethod): boolean {
  const walletPaymentMethodCodes = [PaymentMethodCode.GOOGLE_PAY, PaymentMethodCode.APPLE_PAY];
  const normalizedWalletPaymentMethodCode = walletPaymentMethodCodes.map(normalize);
  return normalizedWalletPaymentMethodCode.includes(normalize(type.paymentMethodCode));
}

/**
 * Find out if a payment type is an apple pay credit card
 * @param type The payment type to test
 */
export function filterForApplePayWalletPayment(type: WalletPayment): boolean {
  return (
    type.paymentMethodCode === PaymentMethodCode.APPLE_PAY &&
    validString(type.paymentMethodName) &&
    normalize(type.paymentMethodName).includes(PaymentType.APPLE_PAY)
  );
}

/**
 * Find out if a payment type is a google pay payment type
 * @param card The credit card to test
 */
export function filterForGooglePayWalletPayment(type: WalletPayment): boolean {
  return (
    type.paymentMethodCode === PaymentMethodCode.GOOGLE_PAY &&
    validString(type.paymentMethodName) &&
    normalize(type.paymentMethodName).includes(PaymentType.GOOGLE_PAY)
  );
}

/**
 * Find out if a credit card is a cybersource credit card (not cybersource apple pay or google pay)
 * @param card The credit card to test
 */
export function filterCardsByPaymentProvider(paymentProvider: PaymentProviderType): (card: CreditCard | PaymentMethod) => boolean {
  const isTargetProvider = isPaymentProvider(paymentProvider);
  return (card: CreditCard | PaymentMethod) => isTargetProvider(card.paymentProviderName) && card.paymentMethodCode === PaymentMethodCode.CREDIT_CARD;
}

/**
 * Find out if a credit card is a cybersource credit card (not cybersource apple pay or google pay)
 * @param card The credit card to test
 */
export const filterCyberSourceCards = filterCardsByPaymentProvider(PaymentProviderType.Cybersource);

/**
 * Find out if a credit card is a adyen credit card (not adyen apple pay or google pay)
 * @param card The credit card to test
 */
export const filterAdyenCards = filterCardsByPaymentProvider(PaymentProviderType.Adyen);

/**
 * Find out if a credit card is a freedompay credit card (not freedompay apple pay or google pay)
 * @param card The credit card to test
 */
export const filterFreedomPayCards = filterCardsByPaymentProvider(PaymentProviderType.FreedomPay);

/**
 * Find out if a credit card has 3ds enabled for cybersource
 * @param card The credit card to test
 */
export const filter3DSCards = ({ enabled3ds, paymentProviderName }: PaymentMethod): boolean => enabled3ds && isCybersource(paymentProviderName);

/**
 * Returns the payment merchant ID by finding a credit card that has the code in its raw card type list.
 * @param creditCards The credit cards to test
 * @param code The code to match in the raw card type list
 * @throws {Error} error if code is not found
 */
export function getPaymentMerchantIdByRawCardType(creditCards: CreditCard[] = [], code: string): number {
  const found = creditCards.find((card) => card.rawCardBrand.includes(code));

  if (!found) {
    throw new Error(`unable to find payment type matching code ${code}`);
  }

  return found.paymentMerchantId;
}

/**
 * Returns the payment merchant ID by finding it in a list of cards and matching a key-value pair
 * @param creditCards The credit cards to test
 * @param prop The property look at
 * @param matchingValue The value the property must match on the card
 * @throws {Error} error if payment merchant id is not found by given property.
 */
export function getPaymentMerchantIdBy(creditCards: WalletPayment[], prop: keyof WalletPayment, matchingValue: string): number {
  const found = creditCards.find((card) => card.hasOwnProperty(prop) && normalize(card[prop] as string) === normalize(matchingValue));

  if (!found) {
    throw new Error(`unable to find payment method matching code ${prop}=${matchingValue}`);
  }

  return found.paymentMerchantId;
}

/**
 * Returns the payment configuration by matching the raw card brand
 * @param creditCards The credit cards to test
 * @param code The raw card brand value
 * @throws {Error} error if no matching credit card is found
 */
export function getPaymentConfigurationForCreditCardsByRawCardBrand(creditCards: CreditCard[] = [], code: string): CreditCard {
  const found = creditCards.find((card) => card.rawCardBrand.includes(code));

  if (!found) {
    throw new Error(`unable to find credit card matching rawCardBrand ${code}`);
  }

  return found;
}

/**
 * Returns the payment configuration by matching the payment method code
 * @param creditCards The credit cards to test
 * @param paymentMethodCode The payment method code
 * @throws {Error} error if no matching credit card is found
 */
export function getPaymentConfigurationForCreditCardsByPaymentMethodCode(creditCards: CreditCard[] = [], paymentMethodCode: PaymentMethodCode): CreditCard {
  const found = creditCards.find((card) => card.paymentMethodCode === paymentMethodCode);

  if (!found) {
    throw new Error(`unable to find credit card matching paymentMethodCode ${paymentMethodCode}`);
  }

  return found;
}

/**
 * Returns the payment configuration by matching the payment method code
 * @param walletPayments The wallet payments to test
 * @param paymentMethodCode The payment method code
 * @throws {Error} error if no matching wallet payment is found
 */
export function getPaymentConfigurationForWalletPaymentsByPaymentMethodCode(
  walletPayments: WalletPayment[] = [],
  paymentMethodCode: PaymentMethodCode
): WalletPayment {
  const found = walletPayments.find((walletPayment) => walletPayment.paymentMethodCode === paymentMethodCode);

  if (!found) {
    throw new Error(`unable to find wallet payment matching paymentMethodCode ${paymentMethodCode}`);
  }

  return found;
}

/**
 * Returns the payment configuration by matching the raw card brand
 * @param walletPayments The wallet payment to test
 * @param code The raw card brand value
 * @throws {Error} error if no matching wallet payment is found
 */
export function getPaymentConfigurationForWalletPaymentsByRawCardBrand(walletPayments: WalletPayment[], code: string): WalletPayment {
  const found = walletPayments.find((card) => card.rawCardBrand.includes(code));

  if (!found) {
    throw new Error(`unable to find wallet payment matching rawCardBrand ${code}`);
  }

  return found;
}

/**
 * Returns matching adyen's card type name
 * @function
 * @public
 * @throws {Error} when there is no card type to map
 * @param accessoPayCardType accesso's card type name
 * @returns adyen's card type name
 */
export function mapAccessoPayToAdyenCardType(accessoPayCardType: string): string {
  if (!validString(accessoPayCardType)) {
    return '';
  }

  switch (normalize(accessoPayCardType)) {
    case 'americanexpress':
      return 'amex';
    case 'mistercash':
    case 'bancontact':
    case 'bancontactdesktop':
      return 'bcmc';
    case 'cartesbancaires':
      return 'cartebancaire';
    case 'chinaunionpay':
      return 'cup';
    case 'dankort':
      return 'dankort';
    case 'dinersclub':
      return 'diners';
    case 'discovercard':
    case 'discover':
      return 'discover';
    case 'jcb':
      return 'jcb';
    case 'laser':
      return 'laser';
    case 'mastercard':
      return 'mc';
    case 'maestrointernational':
      return 'maestro';
    case 'maestroukdomestic':
      return 'maestrouk';
    case 'solo':
      return 'solo';
    case 'visa':
      return 'visa';
    case 'visadankort':
      return 'visadankort';
    default:
      throw new Error(`unable to map accesso pay to adyen card type (${accessoPayCardType})`);
  }
}

/**
 * This function should only be used to standardize the card type we use when sending it to the parent application.
 * @param cardType The card type we got from the payment authorization response
 */
export function standardizeCreditCardType(cardType: string): string {
  const card = normalize(cardType);
  switch (card) {
    case '001':
    case 'visa':
    case 'vis':
      return 'visa';
    case '002':
    case 'mas':
      return 'mastercard';
    case '003':
    case 'amex':
    case 'americanexpress':
      return 'amex';
    case '004':
    case 'discovercard':
    case 'discover':
      return 'discover';
    case '005':
    case 'dinersclub':
    case 'diners':
      return 'diners';
    case '006':
    case 'carteblanche':
      return 'carteblance';
    case '007':
      return 'jcb';
    case '014':
      return 'enroute';
    case '021':
      return 'jal';
    case '024':
    case 'maestroukdomestic':
    case 'maestrouk':
      return 'maestrouk';
    case '027':
    case 'nicoshousecard':
      return 'nicoshouse';
    case '031':
      return 'visadelta';
    case '033':
      return 'visaelectron';
    case '034':
    case 'dankort':
      return 'dankort';
    case '036':
    case 'cartebancaire':
      return 'cartesbancaires';
    case '037':
      return 'cartasi';
    case '040':
      return 'uatp';
    case '042':
    case 'maestro':
    case 'maestrointernational':
      return 'maestrointl';
    case '050':
      return 'hipercard';
    case '051':
      return 'aura';
    case '053':
    case 'oricohousecard':
      return 'orico';
    case '054':
      return 'elo';
    case '061':
      return 'rupay';
    case '062':
    case 'chinaunionpay':
    case 'cup':
      return 'unionpay';
    default:
      return card;
  }
}

/**
 * This function should only be used to retrieve repetetive events for payment authorize errors.
 * @param response The payment authorization response
 * @param paymentType The payment type used for authorization
 * @returns actions to be dispatched
 */
export function getPaymentErrorActions(response: AuthorizeResponse, paymentType: PaymentType): Action[] {
  const actions: Action[] = [sendAnalyticsEventAction({ action: 'pay', category: paymentType, label: 'payment failed', nonInteraction: true })];
  let messageError: Partial<PaymentFailed> = {};

  if (validObject(response)) {
    messageError = {
      errorCode: response.error_code,
      reason: response.error_msg,
    };
  } else {
    messageError = {
      errorCode: ERROR_CODE_RESPONSE_NOT_OBJECT,
    };
  }
  actions.push(
    sendPaymentFailed({
      payload: {
        ...messageError,
        paymentType,
      },
    })
  );
  return actions;
}

/**
 * This function should used for gathering authorization info for finalizing payment.
 * @param authResponse The payment authorization response
 * @param paymentConfiguration The card configuration used for authorization
 * @param paymentType The payment method type
 * @param merchantId The store merchant id
 * @returns formatted data for finalizing a payment
 * @throws {Error} invalid authorize response object
 */
export function buildCardPaymentDetailsFromAuthResponse(
  authResponse: AuthorizeResponse,
  paymentConfiguration: CreditCard | AlternatePayment,
  paymentType: PaymentType,
  merchantId: number
): AuthorizedPaymentInfo {
  if (!validObject(authResponse)) {
    throw new Error(`unable to parse authorize response`);
  }

  const authPaymentInfo: AuthorizedPaymentInfo = {
    amount: authResponse.amount,
    method: paymentType,
    approval_code: authResponse.approval_code,
    authId: authResponse.paymentReference,
    expire_date: authResponse.card_expire_date,
    maskedPan: authResponse.masked_pan,
    legacy: {
      authId: authResponse.auth_id,
      paymentMerchantId: merchantId,
      paymentMethodCode: paymentConfiguration.paymentMethodCode,
      paymentProviderName: paymentConfiguration.paymentProviderName,
      rawCardBrand: paymentConfiguration.rawCardBrand,
    },
    lastFour: authResponse.card_last_four,
    cardType: authResponse.card_type_code,
  };

  if (validObject(authResponse.donation)) {
    authPaymentInfo.donation = convertDonationResponse(authResponse.donation);
  }

  if (validObject(authResponse.insurance)) {
    authPaymentInfo.insurance = convertInsuranceResponse(authResponse.insurance);
  }

  return authPaymentInfo;
}

/**
 * return the logo url to show over the credit card change event
 * @param card card data
 * @param provider payment provider configured
 * @returns
 */
export function getCreditCardLogo(card: CreditCard | AlternatePayment | null, assetsPath?): string {
  if (validString(card?.cardImageFileName) && validString(assetsPath)) {
    // AppConfig path
    return `${assetsPath}/${card.cardImageFileName}`;
  } else {
    return `/assets/billing/credit-cards/${getCardBrandName(normalize(card.cardBrandName))}.svg`;
  }
}

/**
 * relates the cardbrandname with the logo brandname
 * @param cardBrandName
 * @param provider
 * @returns
 */
export function getCardBrandName(cardBrandName: string): string {
  switch (cardBrandName) {
    case CreditCardBrand.CHINAUNIONPAY:
      return 'unionpay';
    default:
      return cardBrandName;
  }
}
