import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, filter, map, Observable, switchMap, tap, withLatestFrom } from 'rxjs';
import { AppState } from 'src/app/app.state';
import { sendPaymentComplete } from 'src/app/core/analytics/analytics.actions';
import { apiResponse, postApiRequest } from 'src/app/core/api/api.actions';
import { responseByModule, responseRequestType } from 'src/app/core/api/api.utilities';
import { PollingService } from 'src/app/core/api/polling/polling.service';
import { InsuranceAuthResponse } from 'src/app/core/api/responses/authorize';
import { InitiateAltPayResponse } from 'src/app/core/api/responses/initiate-alt-pay';
import { UpdateAltPayRequest, UpdateAltPayResponse } from 'src/app/core/api/responses/update-alt-pay';
import { AuthorizedPaymentInfo, DonationAuthResponse, PaymentType } from 'src/app/core/application-bridge/application-bridge.models';
import { selectIsMobile } from 'src/app/core/environment/environment.selectors';
import { LocaleService } from 'src/app/core/locale/locale.service';
import { NotificationDialogType } from 'src/app/core/notification/dialog/confirm-dialog/confirm-dialog.component';
import { showNotificationAction } from 'src/app/core/notification/notification.actions';
import { hidePageSpinner } from 'src/app/core/page-spinner/page-spinner.actions';
import { selectLineItemsAndFees } from 'src/app/core/parent-config/parent-config.selectors';
import { PaymentMethodCode } from 'src/app/core/payment-configuration/payment-configuration.model';
import { routeTo, routeToWithOptions } from 'src/app/core/routing/routing.actions';
import { TemporaryLoggerService } from 'src/app/core/temporary-logger/temporary-logger.service';
import { ApiRequestType } from 'src/app/shared/enums/api.enums';
import { AltPayRedirectDetails, mapAltPayAuthResponseToPaymentComplete } from '../billing/alternate-payments/alternate-payments.utils';
import { sendPaymentPending } from '../billing/billing.actions';
import { selectPaymentCompleteUserDetails } from '../billing/billing.selectors';
import { formatSingleAuthPayment } from '../billing/billing.utils';
import { DeliveryState } from '../delivery/delivery.state';
import { GiftCardState } from '../gift-card/gift-card.state';
import { selectAltPaymentConfig } from './../../core/payment-configuration/payment-configuration.selectors';
import {
  checkPaymentStatusAction,
  handlePollTimeoutAction,
  initiateTmbThanachartAction,
  pollPaymentStatusAction,
  sendTTBPaymentPending,
  stopPollingAction,
} from './tmbthanachart.actions';

export interface buildPaymentCompletePayloadParams {
  InitAltPayResponse: InitiateAltPayResponse | UpdateAltPayResponse;
  paymentDetails: Partial<AltPayRedirectDetails>;
  amount?: number;
  giftCardState?: GiftCardState;
  deliveryStatus?: DeliveryState;
  demographics?: Partial<DemographicsFormData>;
  donation?: DonationAuthResponse;
  insurance?: InsuranceAuthResponse;
}

/**
 * The different type of `status` values
 */
export enum TmbThanachartResponseCodeStatus {
  NOT_FOUND = '001',
  ERROR = '999',
  OK = '000',
}

@Injectable({
  providedIn: 'root',
})
export class TmbThanachartEffects {
  /**
   * The constructor
   * @param actions$ Our action observable
   * @param store The store
   * @param localeService The locale service
   * @param pollingService The polling service
   * @param tempLogger Node logger
   */
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private localeService: LocaleService,
    private pollingService: PollingService,
    private tempLogger: TemporaryLoggerService
  ) {}

  /**
   * This effect is triggered when the user clicks on Pay
   * and the card is enrolled for 3DS
   */
  initializeTmbPayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initiateTmbThanachartAction),
      withLatestFrom(this.store.select(selectIsMobile)),
      switchMap(([{ payload }, isMobile]) => [
        routeToWithOptions({
          routeParams: ['redirect-info'],
          options: { queryParams: { ...payload, isMobile } },
        }),
      ])
    )
  );

  /**
   * This effect is triggered to check payment status
   */
  checkPaymentStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(checkPaymentStatusAction),
      withLatestFrom(this.store.select(selectLineItemsAndFees)),
      map(([{ payload }, { cartItems, fees }]) => ({
        payload,
        reqBody: <UpdateAltPayRequest>{
          auth_id: payload?.authId,
          merchant_id: payload?.paymentMerchantId,
          payload: '',
          payment_type: payload?.paymentMethodCode,
          cartItems,
          fees,
        },
      })),
      map((data) => pollPaymentStatusAction(data))
    )
  );

  /**
   * This effect is triggered when updateAltPayment request is responsed, it check the payment status before complete the order
   * If TMB reponse failed we complete the order as pending status, since they not have a pending status.
   * @deprecated Using APERS flow in favor of polling until we decide otherwise
   */
  handlePaymentStatusResp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(apiResponse),
      filter(responseRequestType(ApiRequestType.UPDATE_ALT_PAY)),
      filter(responseByModule(PaymentType.TMBTHANACHART)),
      filter(() => !this.pollingService.isDetroyed()),
      withLatestFrom(this.store.select(selectPaymentCompleteUserDetails)),
      switchMap(([{ isOk, response: apiResponse, callbackData }, user]) => {
        const response = <UpdateAltPayResponse>apiResponse;
        if (isOk && response.result_code === TmbThanachartResponseCodeStatus.OK) {
          const paymentAuth: AuthorizedPaymentInfo = {
            ...mapAltPayAuthResponseToPaymentComplete(response, { ...callbackData, paymentType: response.paymentType as PaymentType }),
            awaitingAuthorization: true,
          };

          return [
            hidePageSpinner({ initiator: 'TmbThanachart updatePayments results' }),
            sendPaymentComplete({
              payload: formatSingleAuthPayment(paymentAuth, user),
            }),
            stopPollingAction({ payload: response }),
          ];
        } else {
          // do nothing
          return EMPTY;
        }
      })
    )
  );

  /**
   * Poll TTB payment status
   * @deprecated Using APERS flow in favor of polling until we decide otherwise
   */
  pollPaymentStatus$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(pollPaymentStatusAction),
        tap((action) => this.tempLogger.log('Polling starts - params: ', JSON.stringify(action))),
        switchMap((action) =>
          this.pollingService.poll({
            observable: updateAltPay({ store: this.store, reqBody: action.reqBody, payload: action.payload }),
            timeout: action.payload.poll.timeout,
          })
        )
      ),
    { dispatch: false }
  );

  /**
   * Stop polling payment status
   */
  stopPolling$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(stopPollingAction),
        tap(() => this.pollingService.stopPolling()),
        tap((action) => this.tempLogger.log('Polling stops - params: ', JSON.stringify(action)))
      ),
    { dispatch: false }
  );

  /**
   * Handle poll timeout
   */
  handlePollTimeout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(handlePollTimeoutAction),
      tap((action) => this.tempLogger.log('Polling timeout: ', JSON.stringify(action.payload))),
      map((action) =>
        showNotificationAction({
          buttonLabel: this.localeService.get('common.close'),
          dialogType: NotificationDialogType.GENERAL,
          initiator: 'tmbthanachart redirect failure',
          message: this.localeService.get('redirectinfo.pollTimeout'),
          onClose: () => this.store.dispatch(routeTo({ payload: ['select'] })),
        })
      )
    )
  );

  /**
   * Handle TTB Payment complete
   */
  handlePaymentComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendTTBPaymentPending),
      withLatestFrom(this.store.select(selectAltPaymentConfig(PaymentMethodCode.TTB_QR_CODE))),
      map(([action, ttbConfig]) => {
        const { amount, authId, paymentReference, donation, insurance } = action.payload;
        const payload: AuthorizedPaymentInfo = {
          amount,
          approval_code: '',
          authId: paymentReference,
          method: PaymentType.TTB_QR_CODE,
          legacy: {
            authId,
            paymentMerchantId: ttbConfig.paymentMerchantId,
            paymentProviderName: ttbConfig.paymentProviderName,
            paymentMethodCode: ttbConfig.paymentMethodCode,
            rawCardBrand: ttbConfig.cardBrandCode,
          },
          lastFour: '',
          cardType: '',
          expire_date: '',
          donation,
          insurance,
        };

        return sendPaymentPending({ payload });
      })
    )
  );
}

/**
 * Call UpdateAltPay endpoint
 * @param request
 * @returns
 */
function updateAltPay({ store, reqBody, payload }) {
  return new Observable((observer) => {
    store.dispatch(
      postApiRequest({
        requestType: ApiRequestType.UPDATE_ALT_PAY,
        body: reqBody,
        params: { module: 'tmbthanachart' },
        callbackData: payload,
      })
    );
    observer.next();
    observer.complete();
  });
}
