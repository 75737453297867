import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

/**
 * A custom validator that ensures a form control value is not empty or composed solely of whitespace.
 *
 * This validator differs from Angular's built-in `Validators.required` in the following way:
 * - `Validators.required` only checks if the control value is null, undefined, or an empty string (`''`).
 * - `requiredValidator` additionally trims the value and checks if the resulting string is empty,
 *   making it suitable for cases where input containing only spaces should be considered invalid.
 *
 */
export function requiredValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const isWhitespace = (control.value || '').trim().length === 0;
    return isWhitespace ? { required: true } : null;
  };
}
